import * as React from 'react';
import styled from 'styled-components';
import Gallery from '../components/Gallery';
import Section from '../components/Section';
import HeroImage from '../components/HeroImage';
import Hero from '../components/Hero';
import Wrapper from '../components/Wrapper';

const Main = styled.main`
  background: #fff;
  color: #101119;
  line-height: 1.75;

  h1 {
    color: #fff !important;
    font-size: 15rem;
    font-family: 'Inter';
    text-shadow: none;
    text-align: center;
    text-transform: uppercase;
  }

  h3 {
    color: #101119;
    font-weight: 700;
    max-width: 700px;
    font-size: 2rem;
    margin-bottom: 0;
  }

  p {
    font-size: 16px;
    max-width: 700px;
    margin: 1rem 0;
  }

  a {
    color: inherit;
    text-decoration: none;
    transition: 0.2s ease-in-out;

    &:hover {
      color: #c29953;
    }
  }

  .type {
    line-height: 1;
    padding: 0;
    margin: 0;
    margin-bottom: 2rem;
  }

  @media (max-width: 1200px) {
    h1 {
      font-size: 6rem;
    }
  }

  @media (max-width: 600px) {
    h1 {
      font-size: 4rem;
    }
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 0 2rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const ColSmall = styled.div`
  width: 33.33%;
  padding: 0 2rem;

  @media (min-width: 901px) and (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
  }
`;

const ColLarge = styled.div`
  width: 66.66%;
  padding: 0 2rem;

  @media (min-width: 901px) and (max-width: 1200px) {
    width: 50%;
  }

  @media (max-width: 900px) {
    width: 100%;
    padding: 0;
  }
`;

const Lead = styled.p`
  font-size: 20px !important;
  padding: 1.5rem 0;
`;

const Image = styled.img`
  padding: 1.5rem 0;
  position: relative;
  top: 1.5rem;
  width: 100%;
`;

export default function ClientTemplate({ pageContext: { client } }) {
  return (
    <Main>
      <HeroImage className={client.slug} />
      <Hero>
        <h1>
          <span>{client.firstName}</span>
        </h1>
      </Hero>

      <Section>
        <Wrapper>
          <Row>
            <ColSmall>
              <Lead>
                <strong>Height:</strong> {client.height}
                <br />
                <strong>DOB:</strong> {client.dob} <br />
                <strong>Birthplace:</strong> {client.birthplace} <br />
                <strong>Instagram:</strong>{' '}
                <a
                  href={`https://instagram.com/${client.ig}`}
                  target="__blank"
                  rel="noreferrer"
                >
                  @{client.ig}
                </a>
              </Lead>
            </ColSmall>

            <ColLarge>
              <h3>{client.name}</h3>
              <p className="type">{client.clientType} Client</p>
              <p dangerouslySetInnerHTML={{ __html: client.bio }} />
              <br></br>

              <Gallery className={client.slug} />

              <Gallery className={`${client.slug}2`} />
            </ColLarge>
          </Row>
        </Wrapper>
      </Section>
    </Main>
  );
}
