import React, { FC } from 'react';
import styled from "styled-components";

import nate1 from '../../assets/images/clients/player-nate1.jpeg';
import kyler1 from '../../assets/images/clients/player-kyler1.jpeg';
import enoch1 from '../../assets/images/clients/player-enoch1.jpeg';
import mouhamed1 from '../../assets/images/clients/player-mouhamed1.jpeg';
import jalen1 from '../../assets/images/clients/player-jalen1.jpeg';
import keeshawn1 from '../../assets/images/clients/player-keeshawn1.jpeg';
import jamal1 from '../../assets/images/clients/player-jamal1.jpeg';
import diongue1 from '../../assets/images/clients/player-diongue1.jpeg';
import jalenhill1 from '../../assets/images/clients/player-jalenhill1.jpeg';
import milos1 from '../../assets/images/clients/player-milos1.jpeg';
import glenn1 from '../../assets/images/clients/player-glen1.jpeg';

import nate2 from '../../assets/images/clients/player-nate2.jpeg';
import kyler2 from '../../assets/images/clients/player-kyler2.jpeg';
import enoch2 from '../../assets/images/clients/player-enoch2.jpeg';
import mouhamed2 from '../../assets/images/clients/player-mouhamed2.jpeg';
import jalen2 from '../../assets/images/clients/player-jalen2.jpeg';
import keeshawn2 from '../../assets/images/clients/player-keeshawn2.jpeg';
import jamal2 from '../../assets/images/clients/player-jamal2.jpeg';
import diongue2 from '../../assets/images/clients/player-diongue2.jpeg';
import jalenhill2 from '../../assets/images/clients/player-jalenhill2.jpeg';
import milos2 from '../../assets/images/clients/player-milos2.jpeg';
import glenn2 from '../../assets/images/clients/player-glen2.jpeg';

const Image = styled.div`
	background-size: cover;
	background-position: center;
	width: 100%;
	height: 0;
	padding-top: 75%;
	margin-bottom: 1rem;

	&.enochboakye {
		background-image: url(${enoch1});
	}

	&.jalencook {
		background-image: url(${jalen1});
	}

	&.jamalshead {
		background-image: url(${jamal1});
	}

	&.keeshawnbarthelemy {
		background-image: url(${keeshawn1});
	}

	&.kyleredwards {
		background-image: url(${kyler1});
	}

	&.mouhamedgueye {
		background-image: url(${mouhamed1});
	}

	&.naterobinson {
		background-image: url(${nate1});
	}

	&.enochboakye2 {
		background-image: url(${enoch2});
	}

	&.jalencook2 {
		background-image: url(${jalen2});
	}

	&.jamalshead2 {
		background-image: url(${jamal2});
	}

	&.keeshawnbarthelemy2 {
		background-image: url(${keeshawn2});
	}

	&.kyleredwards2 {
		background-image: url(${kyler2});
	}

	&.mouhamedgueye2 {
		background-image: url(${mouhamed2});
	}

	&.naterobinson2 {
		background-image: url(${nate2});
	}

	&.adramediongue {
		background-image: url(${diongue1});
	}

	&.adramediongue2 {
		background-image: url(${diongue2});
	}

	&.jalenhill {
		background-image: url(${jalenhill1});
	}

	&.jalenhill2 {
		background-image: url(${jalenhill2});
	}

	&.milosuzan {
		background-image: url(${milos1});
	}

	&.milosuzan2 {
		background-image: url(${milos2});
	}

	&.glenntaylorjr {
		background-image: url(${glenn1});
	}

	&.glenntaylorjr2 {
		background-image: url(${glenn2});
	}
`;

interface ImageProps {
	className?: string;
}

const GalleryComponent: FC<ImageProps> = (props) => {
  return (
	  <Image className={props.className} />
	)
};
  
export default GalleryComponent;